import React, { useState } from 'react';
import { Parallax } from "react-parallax";
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const image1 = "./img/background/6.jpg";

const Orcament = () => {
    const [formData, setFormData] = useState({
        formQuantity: 0,
        lowComplexityPages: 0,
        mediumComplexityPages: 0,
        highComplexityPages: 0,
        budget: 0
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const calculateBudget = () => {
        const { formQuantity, lowComplexityPages, mediumComplexityPages, highComplexityPages } = formData;
        const tempoFormulario = 1;
        const tempoDeploy = 3;
        const areaLogin = 4;
        const tempoBaixaComplex = 3;
        const tempoMediaComplex = 5;
        const tempoAltaComplex = 7;
        const tempoReuniaoInicial = 3;
        const tempoPreparacaoPrototipoPapel = 7;
        const tempoPreparacaoPrototipoOnline = 16;
        const tempoPreparacacaoDocTeste = 1;
        const tempoReuniaoAlinhamento = 1;
        const homemHora = 140.35;

        const tempoAdm = tempoDeploy + areaLogin + tempoReuniaoInicial + tempoPreparacaoPrototipoPapel + tempoPreparacaoPrototipoOnline;
        const totalForm = tempoFormulario * formQuantity;
        const totalBaixa = tempoBaixaComplex * lowComplexityPages;
        const totalMedia = tempoMediaComplex * mediumComplexityPages;
        const totalAlta = tempoAltaComplex * highComplexityPages;
        const totalPreparacaoDocTeste = tempoPreparacacaoDocTeste * (parseInt(formQuantity) * 2) + parseInt(lowComplexityPages) + parseInt(mediumComplexityPages) + parseInt(highComplexityPages);


        const totalHoras = tempoAdm + totalForm + totalBaixa + totalMedia + totalAlta + totalPreparacaoDocTeste + tempoReuniaoAlinhamento
        
        const total = totalHoras * homemHora;
        setFormData({ ...formData, budget: total });
    };

    return (
        <div className="section bg-top bg-bottom py-0">
            <Parallax className="py-5" bgImage={image1} strength={300}>
                <div className="py-5 position-relative">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center"
                                data-aos="fade-up"
                                data-aos-once="true"
                                data-aos-delay="0"
                                data-aos-duration="1000"
                                data-aos-easing="ease"
                            >
                                <h2>Estime seu Orçamento:</h2>
                                <div className="space-border"></div>
                            </div>
                        </div>
                        <div className="col-lg-8 offset-lg-2"
                            data-aos="fade-up"
                            data-aos-once="true"
                            data-aos-delay="200"
                            data-aos-duration="1000"
                            data-aos-easing="ease"
                        >
                            <div className="form-border">
                            <div className="row">
                            <div className="col-md-6">
                                <label htmlFor="formQuantity">Quantidade de Formulários</label>
                                <input type="number" name="formQuantity" id="formQuantity" className="form-control" placeholder="Quantidade de Formulários" value={formData.formQuantity} onChange={handleChange} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="lowComplexityPages">Páginas de Baixa Complexidade</label>
                                <input type="number" name="lowComplexityPages" id="lowComplexityPages" className="form-control" placeholder="Páginas de Baixa Complexidade" value={formData.lowComplexityPages} onChange={handleChange} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="mediumComplexityPages">Páginas de Média Complexidade</label>
                                <input type="number" name="mediumComplexityPages" id="mediumComplexityPages" className="form-control" placeholder="Páginas de Média Complexidade" value={formData.mediumComplexityPages} onChange={handleChange} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="highComplexityPages">Páginas de Alta Complexidade</label>
                                <input type="number" name="highComplexityPages" id="highComplexityPages" className="form-control" placeholder="Páginas de Alta Complexidade" value={formData.highComplexityPages} onChange={handleChange} />
                            </div>

                            </div>
                            <div className="mt-3">
                                <button className="btn-main" onClick={calculateBudget}>Fazer Estimativa</button>
                                <p className='mt-3'>Total estimado do projeto: <b>R$ {formData.budget.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</b></p>
                                <h4>Atenção</h4>
                                <p>Está estimativa de orçamento inclui: <br/><br/>
                                - Deploy da aplicação e a consultoria para escolha de uma hospedagem com baixo custo de manutenção;<br/>
                                - Criação de área de login, com: cadastro, login, reenvio de senha e alteração de senha; <br/>
                                - As páginas dos formulários referente a cadastro e edição e a listagem dos ítens em formato de tabela; <br/>
                                - Reunião inical para o levantamento dos requisitos;<br/>
                                - Criação de um rascunho de protótipo em papel;<br/>
                                - Criação de um protótipo online para a aprovação do cliente; <br/>
                                - Uma reunião de alinhamento durante o projeto; <br/>
                                - Criação do Banco de dados em SQL podendo ser PostgreSQL ou MySql; <br/>
                                - Toda a documentação do sistema; <br/>
                                - Todo sistema testado utilizando testes unitários automáticos. <br/>
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Parallax>
        </div>
    );
}
export default Orcament;

import React from 'react';
import AOS from 'aos';
AOS.init();

const whatido = () => {
    return(
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center"
                    data-aos="fade-up"
                    data-aos-delay="0"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                    >
                    <h2>O que fazemos</h2>
                    <div className="space-border"></div>
                </div>
            </div>
            <div className="spacer-single"></div>
            <div className="row">
                <div className="col-lg-6"
                    data-aos="fade"
                    data-aos-delay="300"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                    >
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="icon_genius id-color-2"></i>
                            <div className="text">
                                <h3>Explorando o Django na Pandora Inovações</h3>
                                    <p>O Django é mais do que apenas um framework web. É uma ferramenta poderosa que oferece segurança, robustez e eficiência para atender às necessidades dos nossos clientes.</p>
                                    <p>Na Pandora Inovações, utilizamos o Django para desenvolver sistemas que são seguros, confiáveis e escaláveis. Com sua estrutura sólida e sua abordagem baseada em padrões, podemos criar aplicações web que garantem a integridade dos dados e a proteção contra vulnerabilidades de segurança.</p>
                                    <p>Além disso, o Django oferece uma ampla gama de recursos e funcionalidades que tornam o desenvolvimento rápido e eficiente. Desde a autenticação de usuários até a gestão de sessões e a administração do banco de dados, o Django simplifica o processo de criação de aplicações web complexas.</p>
                                    <p>Com o Django, podemos construir sistemas que atendem às demandas mais exigentes dos nossos clientes, garantindo ao mesmo tempo uma experiência de usuário excepcional. Se você está em busca de uma solução tecnológica confiável e de alta qualidade, a Pandora Inovações e o Django são a combinação perfeita para o seu projeto. Venha descobrir como podemos transformar suas ideias em realidade com a segurança e robustez do Django.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6" 
                    data-aos="fade"
                    data-aos-delay="400"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                    >
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="icon_tools id-color-2"></i>
                            <div className="text">
                                <h3>Desenvolvimento</h3>
                                    <p>Na Pandora Inovações, nossa equipe de desenvolvimento é apaixonada por oferecer soluções tecnológicas de ponta que fazem a diferença na vida das pessoas. Nosso foco está no conhecimento em Django e programação em Python, acreditando firmemente que a implementação correta das regras de negócio de um projeto é fundamental para o sucesso.</p>
                                    <p>Utilizando nossa vasta experiência anterior, buscamos sempre agregar valor aos projetos que desenvolvemos. O conhecimento em Django e programação em Python é a base de nossa atuação, permitindo-nos criar sistemas completos e eficientes que atendem às necessidades exclusivas de cada cliente.</p>
                                    <p>Para alcançar isso, nos especializamos em desenvolver sistemas sob medida, feitos sob encomenda para cada cliente. Confiamos no poder do Django como nosso framework principal, combinado com as linguagens de programação Python e JavaScript. Essa combinação nos permite criar sistemas robustos e escaláveis, enquanto garantimos a qualidade através de testes rigorosos em todas as etapas do processo de desenvolvimento.</p>
                                    <p>Na Pandora Inovações, estamos comprometidos em oferecer soluções tecnológicas de alta qualidade que impulsionam o sucesso de nossos clientes. Se você está em busca de uma equipe de desenvolvimento dedicada e experiente, estamos prontos para transformar suas ideias em realidade. Venha descobrir como podemos ajudá-lo a alcançar seus objetivos com nossa abordagem centrada no cliente e nossa expertise em Django e programação em Python.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-center mt-5">
                        <h2 data-aos="fade-up"
                            data-aos-delay="0"
                            data-aos-duration="1000"
                            data-aos-easing="ease"
                            data-aos-once="true">Ganhe tempo, tenha uma equipe profissional dedicada ao seu projeto.</h2>                        
                    </div>
                    <div className="col-md-12 text-center m-auto"
                        data-aos="fade-up"
                        data-aos-delay="60"
                        data-aos-duration="1000"
                        data-aos-easing="ease"
                        data-aos-once="true"
                        >
                        <p>
                            Desenvolver um software ou um site é como construir um imóvel sob medida para suas necessidades. Assim como na construção de uma casa, há várias etapas envolvidas, desde a definição dos requisitos até a implementação e o acabamento.<br/><br/>
                            Nossa abordagem no desenvolvimento de softwares é focada em entender profundamente as necessidades do cliente e traduzi-las em soluções tecnológicas eficientes. Com expertise em Django e programação em Python, nossa equipe domina todas as áreas necessárias para criar sistemas robustos e escaláveis, desde a análise de requisitos até a escolha dos frameworks e bibliotecas mais adequados.<br/><br/>
                            Entendemos que seu tempo é valioso e sabemos que algumas tarefas técnicas podem consumir horas preciosas. Por isso, estamos aqui para assumir o desenvolvimento do seu sistema como se fosse nosso próprio projeto, garantindo que você possa focar em outras tarefas importantes do seu negócio.<br/><br/>
                            Com uma equipe experiente e versátil, estamos preparados para enfrentar os desafios mais complexos e entregar resultados surpreendentes. Faça um orçamento conosco e descubra como podemos transformar suas ideias em realidade de forma rápida e eficiente. Estamos ansiosos para trabalhar com você e superar suas expectativas.<br/><br/>
                        </p>
                    </div>
			    </div>
                {/*<div className="col-lg-12"
                    data-aos="fade"
                    data-aos-delay="500"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                    >
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="icon_camera_alt id-color-2"></i>
                            <div className="text">
                                <h3>Photography</h3>
                                Ex velit cupidatat magna voluptate deserunt quis et dolor adipisicing elit culpa ad exercitation proident irure deserunt irure.
                            </div>
                        </div>
                    </div>
                </div>*/}
            </div>
        </div>
    );
}

export default whatido;
import React from 'react';

const footer = () => {
    return(
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <a href="/" target="_blank" rel="noreferrer">
                            <span className="copy">&copy; Copyright 2022 - Desenvolvido pela Pandora Inovações</span>
                        </a>
                        <p className='mb-0 pt-0'>AV PAULISTA, 1636 CONJ 4 PAVMTO15 - BELA VISTA - SAO PAULO - SP - CEP: 01.310-200</p>
                        <p className='mt-0 pt-0'>Pandora Inovações CNPJ: 54.315.303/0001-22</p>
                    </div>
                    {/* <div className="col-md-6">
                        <div className="social-icons">
                            <a href="https://www.linkedin.com/in/jean-fas/" rel="noreferrer"><i className="fa fa-linkedin"></i></a>
                            <a href="https://github.com/jfas84" rel="noreferrer"><i className="fa fa-github"></i></a>
                            <a href="https://twitter.com" rel="noreferrer"><i className="fa fa-twitter"></i></a>
                            <a href="https://pinterest.com/" rel="noreferrer"><i className="fa fa-pinterest"></i></a>
    <a href="https://www.envato.com" target="_blank" rel="noreferrer"><i className="fa fa-rss"></i></a>
                        </div>￼
Pandora Inovações
DESENVOLVIMENTO DE|
Brasil
Portugal
EUA
Este site utiliza cookies para garantir a melhor experiência possível. Ao continuar navegando, você concorda com o uso de cookies e a política de privacidade. Veja nossa política de privacidade aqui.


                    </div> */}
                </div>
            </div>
        </footer>
    );
}

export default footer;
import React, { useState, useEffect } from "react";
import { createGlobalStyle } from "styled-components";
import AOS from "aos";
import 'aos/dist/aos.css';

AOS.init();

const GlobalStyles = createGlobalStyle`
    body.hide {
        overflow-y: hidden; /* Hide vertical scrollbar */
        overflow-x: hidden; /* Hide horizontal scrollbar */
    }
`;

const Pergunta = function() {
    const [questions, setQuestions] = useState([]);
    const [lightboxContent, setLightboxContent] = useState(null);

    useEffect(() => {
        fetch('https://faq-pandora.negocioteca.com.br/api/faqs/')
            .then(response => response.json())
            .then(data => setQuestions(data))
            .catch(error => console.error('Error fetching FAQ data:', error));
    }, []);

    const handleQuestionClick = (content) => {
        setLightboxContent(content.replace(/\r\n/g, '<br />'));
        document.body.classList.add('hide');
    };

    const handleLightboxClose = () => {
        setLightboxContent(null);
        document.body.classList.remove('hide');
    };

    return (
        <div className="container">
            <GlobalStyles/>
            <div className="spacer-single"></div>
            <div className="row">
                <div className="col-md-12 text-center">
                    <h2>Dúvidas Frequentes</h2>
                    <div className="space-border"></div>
                </div>
            </div>
            <div className="row" data-aos="fade-up" data-aos-once="true">
                <div className="col-md-12">
                    {questions.map((item, index) => (
                        <div key={index} className="perguntas" onClick={() => handleQuestionClick(item.resposta)}>
                            <h4>{item.pergunta}</h4>
                        </div>
                    ))}
                </div>
            </div>

            {lightboxContent && (
                <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleLightboxClose}></button>
                    </div>
                    <div className="v-center w-100">
                        <div className="mainLightbox container">
                            <div className="row">
                                <div className="col-md-8 offset-md-2">
                                    <div className="blog-read" dangerouslySetInnerHTML={{ __html: lightboxContent }}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Pergunta;

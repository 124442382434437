import React, { useState } from 'react';

const Popup = () => {
  const [showPopup, setShowPopup] = useState(true);

  const acceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setShowPopup(false);
  };

  const redirectToBlankPage = () => {
    window.location.href = '/blank';
  };

  if (!showPopup) {
    return null;
  }

  return (
    <div className="popup-container">
      <div className="popup">
      <p>
        Este site utiliza cookies para garantir a melhor experiência possível. Ao continuar navegando, você concorda com o uso de cookies e a política de privacidade. 
        Veja nossa <a className='link' href="https://drive.google.com/file/d/1twahGG99kjNs3_TfEvySOOifwPEkT-zE/view?usp=sharing" download>política de privacidade</a> aqui.
      </p>

        <div className="button-container">
          <button className='btn-main' onClick={acceptCookies}>Aceitar cookies</button>
          <button className='btn-main' onClick={redirectToBlankPage}>Não aceitar</button>
        </div>
      </div>
    </div>
  );
};

export default Popup;

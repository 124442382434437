import React from 'react';
import Masonry from "react-masonry-component";
import { createGlobalStyle } from 'styled-components';
import AOS from 'aos';
AOS.init();

const GlobalStyles = createGlobalStyle`
    body.hide{
          overflow-y: hidden; /* Hide vertical scrollbar */
          overflow-x: hidden; /* Hide horizontal scrollbar */
        }
`;

const Gallery= function() {
    /* lightbox */
    const [lighbx, setlighbx] = React.useState(false);
    const handleBtnClick = (): void => {
      setlighbx(!lighbx);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const handleBtnClickclose = (): void => {
      setlighbx(!lighbx);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };

    /* lightbox1 */
    const [lighbx1, setlighbx1] = React.useState(false);
    const handleBtnClick1 = (): void => {
      setlighbx1(!lighbx1);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const handleBtnClickclose1 = (): void => {
      setlighbx1(!lighbx1);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };

    /* lightbox2 */
    const [lighbx2, setlighbx2] = React.useState(false);
    const handleBtnClick2 = (): void => {
      setlighbx2(!lighbx2);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const handleBtnClickclose2 = (): void => {
      setlighbx2(!lighbx2);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };

    /* lightbox3 */
    const [lighbx3, setlighbx3] = React.useState(false);
    const handleBtnClick3 = (): void => {
      setlighbx3(!lighbx3);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const handleBtnClickclose3 = (): void => {
      setlighbx3(!lighbx3);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };

    /* lightbox4 */
    const [lighbx4, setlighbx4] = React.useState(false);
    const handleBtnClick4 = (): void => {
      setlighbx4(!lighbx4);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const handleBtnClickclose4 = (): void => {
      setlighbx4(!lighbx4);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };
    return(
            <div className="container">
            <GlobalStyles/>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h2>Portfólio</h2>
                        <div className="space-border"></div>
                    </div>
                </div>
                 <Masonry
                    className={"row my-gallery-class"} elementType={"div"}>
                    <div className="col-lg-6 image-element-class de_modal de_modal" onClick={handleBtnClick}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Maison Invest</h3>
                                <h5 className="d-tag">Site de Apresentação</h5>
                            </div>
                            <img src="./img/gallery/maison-1.png" alt="gallery"/>
                        </div>
                    </div>
                    <div className="col-lg-6 image-element-class de_modal" onClick={handleBtnClick1}
                        data-aos="fade-up"
                        data-aos-once="true"
                        > 
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Lobo Consursos - Plataforma</h3>
                                <h5 className="d-tag">Software Web Educacional</h5>
                            </div>
                            <img src="./img/gallery/1.png" alt="gallery"/>
                        </div>
                    </div>
                    <div className="col-lg-6 image-element-class de_modal" onClick={handleBtnClick2}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Ninho de Falcões RJ</h3>
                                <h5 className="d-tag">Dashboard para o concurso PCERJ</h5>
                            </div>
                            <img src="./img/gallery/ninho-1.png" alt="gallery"/>
                        </div>
                    </div>
                    <div className="col-lg-6 image-element-class de_modal" onClick={handleBtnClick3}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Software de Gestão Condominial</h3>
                                <h5 className="d-tag">Software de Gestão de condomínios</h5>
                            </div>
                            <img src="./img/gallery/sgc-1.png" alt="gallery"/>
                        </div>
                    </div>
                    <div className="col-lg-6 image-element-class de_modal" onClick={handleBtnClick4}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Software de Gestão Escolar</h3>
                                <h5 className="d-tag">Software de Gestão de Escolas e Cursos</h5>
                            </div>
                            <img src="./img/gallery/sge-1.png" alt="gallery"/>
                        </div>
                    </div>
                    {/*<div className="col-lg-6 image-element-class de_modal" onClick={handleBtnClick}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Lobo Consursos - Plataforma</h3>
                                <h5 className="d-tag">SaaS</h5>
                            </div>
                            <img src="./img/gallery/4.png" alt="gallery"/>
                        </div>
                    </div>
                    <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick1}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Lobo Consursos - Plataforma</h3>
                                <h5 className="d-tag">SaaS</h5>
                            </div>
                            <img src="./img/gallery/5.jpg" alt="gallery"/>
                        </div>
                    </div>
                    <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick2}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Lobo Consursos - Plataforma</h3>
                                <h5 className="d-tag">SaaS</h5>
                            </div>
                            <img src="./img/gallery/6.jpg" alt="gallery"/>
                        </div>
    </div>*/}
                  </Masonry>

                  {/* lightbox */}
                  {lighbx && ( 
                  <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose}></button>
                    </div>
                    <div className="v-center w-100">
                      <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-12">
                                <img src="./img/gallery/maison-1.png" alt="" className="img-fluid"/>
                            </div>
                            <div className="col-lg-6 item">
                                <img src="./img/gallery/maison-2.png" alt="galleryimage" className="img-fluid"/>
                            </div>
                            <div className="col-lg-6 item">
                                <img src="./img/gallery/maison-3.png" alt="galleryimage" className="img-fluid"/>
                            </div>

                            <div className="col-lg-12 de_project-info">
                                <h3>Site de apresentação - Maison Invest</h3>
                                <p>Onde a excelência em investimentos encontra sua expressão digital. O site foi cuidadosamente projetado para oferecer uma experiência única, destacando nossa visão, valores e o excepcional conjunto de serviços que oferecemos aos nossos clientes.</p>
                                <p>Ao navegar no site, você será imerso em um mundo dos investimentos, guiado por uma interface intuitiva e conteúdo envolvente. Aqui estão alguns aspectos que destacam o que torna o site tão especial:</p>
                                <p><b>Visão Clara e Mensagem Impactante: </b>Desde o momento em que você acessa a página inicial, você será recebido por uma visão clara de quem é a Maison e o que representa. A mensagem é apresentada de forma concisa e impactante, transmitindo a missão de excelência em investimentos.</p>
                                <p><b>Design Elegante e Responsivo: </b>O site é mais do que apenas uma vitrine digital; é uma obra de arte funcional. Com um design elegante e responsivo, garantimos uma experiência perfeita em todos os dispositivos, desde desktops até smartphones.</p>
                                <p><b>Conteúdo Persuasivo e Informativo: </b>Cada seção do site é cuidadosamente elaborada para fornecer informações valiosas e persuasivas sobre os serviços, investimento e atendimento. Queremos que você se sinta totalmente informado e confiante ao considerar Maison Invest como seu parceiro de investimento.</p>
                                <p><b>Facilidade de Navegação e Interação: </b>Nossa prioridade foi tornar a navegação no site simples e intuitiva. Com menus claros e uma estrutura de página bem organizada, você pode encontrar facilmente as informações que procura e interagir de forma rápida e eficiente.</p>
                                <p><b>Apresentação Visualmente Atraente: </b>Acreditamos no poder da apresentação visual. É por isso que incorporamos elementos visuais atraentes, como imagens de alta qualidade, para cativar e envolver nossos visitantes.</p>
                                <p>Em resumo, o site de apresentação da Maison Invest é muito mais do que uma simples presença online; é uma porta de entrada para o mundo dos investimentos de excelência. Explore o site e veja o que a Pandora Inovações pode fazer por você.</p>
                                <p><b>Duração do desenvolvimento: 15 dias.</b></p>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa fa-user-o"></i>Cliente: <span>Maison Invest</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-file-text-o"></i>Tipo: <span>Apresentação</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-calendar-o"></i>Ano: <span>2023</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-external-link"></i>Site: <span><a href="https://www.maisoninvest.com.br" target="_blank" rel="noreferrer">www.maisoninvest.com.br</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )}

                  {/* lightbox1 */}
                  {lighbx1 && ( 
                  <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose1}></button>
                    </div>
                    <div className="v-center w-100">
                      <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <div className="row g-4">
                                    <div className="col-lg-12 item">
                                        <img src="./img/single-gallery/pf-2-1.png" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    <div className="col-lg-6 item">
                                        <img src="./img/single-gallery/pf-2-2.png" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    <div className="col-lg-6 item">
                                        <img src="./img/single-gallery/pf-2-3.png" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    <div className="col-lg-12 item">
                                        <img src="./img/single-gallery/pf-2-4.png" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    {/*<div className="col-lg-6 item">
                                        <img src="./img/single-gallery/pf-2-5.jpg" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    <div className="col-lg-6 item">
                                        <img src="./img/single-gallery/pf-2-6.jpg" alt="galleryimage" className="img-fluid"/>
                                    </div>*/}
                                </div>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Plataforma Educacional - Lobo Concursos</h3>
                                <p>O projeto é uma plataforma educacional.<br/>
                                    A tela principal é uma página de apresentação da plataforma com acesso a área restrita através de login e senha.<br/>
                                    Há integração com meio de pagamento, nesse projeto foi usado o PagSeguro. <br/>
                                    <b>No dashboard do administrador, é possível:</b><br/>
                                    - Realizar diversos cadastros e Consultas;<br/>
                                    - Verificar pagamentos;<br/>
                                    - Liberar acessos;<br/>
                                    - Ter visões de: usuário, professor, colaborador em níveis e gestor;<br/>
                                    <b>No dashboard do usuário “aluno”, é possível:</b><br/>
                                    - Realizar questões e exercícios, com diversos filtros;<br/>
                                    - Assistir vídeo aulas;<br/>
                                    - Interagir com os usuários em um fórum;<br/>
                                    - Ler conteúdos;<br/>
                                    - Consultar o seu histórico de acertos e erros, com diversos filtros.<br/>
                                    <b>Duração do desenvolvimento: 6 meses.</b>
                                    </p>                               
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa fa-user-o"></i>Cliente: <span>Renovatio Educação LTDA</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-file-text-o"></i>Tipo: <span>Plataforma Educacional</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-calendar-o"></i>Ano: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-external-link"></i>Site: <span><a href="https://www.loboconcursos.com.br" target="_blank" rel="noreferrer">www.loboconcursos.com.br</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                {/*<blockquote>
                                    "Very well done theme. Versatile, extremely well coded, and gorgeous. That's a great combo. Highly recommended."
                                    <span>John Archi</span>
                                </blockquote>*/}
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )}

                {/* lightbox2 */}
                {lighbx2 && ( 
                  <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose2}></button>
                    </div>
                    <div className="v-center w-100">
                      <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <div className="row g-4">
                                    <div className="col-lg-12 item">
                                        <img src="./img/gallery/ninho-1.png" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    <div className="col-lg-6 item">
                                        <img src="./img/gallery/ninho-2.png" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    <div className="col-lg-6 item">
                                        <img src="./img/gallery/ninho-3.png" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    <div className="col-lg-12 item">
                                        <img src="./img/gallery/ninho-4.png" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    {/*<div className="col-lg-6 item">
                                        <img src="./img/single-gallery/pf-2-5.jpg" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    <div className="col-lg-6 item">
                                        <img src="./img/single-gallery/pf-2-6.jpg" alt="galleryimage" className="img-fluid"/>
                                    </div>*/}
                                </div>
                            </div>
                            <div className="col-lg-4 de_project-info">
                                <h3>Plataforma - Ninho de Falcões RJ</h3>
                                <p>O site Ninho de Falcões RJ foi cuidadosamente desenvolvido pela equipe da Pandora Inovações para atender às necessidades específicas dos candidatos do concurso da PCERJ. Esta plataforma oferece uma experiência completa e personalizada, permitindo que os usuários acompanhem o andamento do concurso, verifiquem sua classificação atualizada e explorem diversos gráficos informativos sobre o certame.</p>
                                <p><b>Recursos Principais:</b></p>
                                <p><b>Acompanhamento do Concurso: </b>Os candidatos têm acesso a uma seção dedicada para acompanhar o progresso do concurso, visualizando sua classificação atualizada e explorando gráficos informativos que apresentam o andamento do certame.</p>
                                <p><b>Cadastro e Atualização de Candidatos: </b>Os administradores do site têm a capacidade de cadastrar novos candidatos e atualizar suas informações, garantindo que os dados estejam sempre precisos e atualizados.</p>
                                <p><b>Dashboard Administrativo: </b>No dashboard administrativo, os administradores têm acesso a uma variedade de recursos poderosos, incluindo a capacidade de realizar cadastros e consultas, liberar acessos e obter insights detalhados sobre usuários.</p>
                                <p><b>Duração do Desenvolvimento: </b>O site Ninho de Falcões RJ foi desenvolvido em um período de 30 dias, garantindo rapidez e eficiência na entrega do projeto.</p>
                                <p>Essa plataforma representa o compromisso da Pandora Inovações em fornecer soluções de alta qualidade e sob medida para atender às necessidades exclusivas de nossos clientes. Estamos dedicados a oferecer uma experiência excepcional aos usuários e a contribuir para o sucesso de cada projeto que empreendemos.</p>                               
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa fa-user-o"></i>Cliente: <span>Comissão de aprovados PCERJ</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-file-text-o"></i>Tipo: <span>Plataforma</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-calendar-o"></i>Ano: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-external-link"></i>Site: <span><a href="https://www.ninhodefalcoesrj.com.br" target="_blank" rel="noreferrer">www.ninhodefalcoesrj.com.br</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                {/*<blockquote>
                                    "Very well done theme. Versatile, extremely well coded, and gorgeous. That's a great combo. Highly recommended."
                                    <span>John Archi</span>
                                </blockquote>*/}
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* lightbox3 */}
                {lighbx3 && ( 
                  <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose3}></button>
                    </div>
                    <div className="v-center w-100">
                      <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <div className="row g-4">
                                    <div className="col-lg-12 item">
                                        <img src="./img/gallery/sgc-2.png" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    <div className="col-lg-6 item">
                                        <img src="./img/gallery/sgc-3.png" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    <div className="col-lg-6 item">
                                        <img src="./img/gallery/sgc-5.png" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    <div className="col-lg-12 item">
                                        <img src="./img/gallery/sgc-4.png" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    <div className="col-lg-12 item">
                                        <img src="./img/gallery/sgc-6.png" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    <div className="col-lg-12 item">
                                        <img src="./img/gallery/sgc-7.png" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    {/*<div className="col-lg-6 item">
                                        <img src="./img/single-gallery/pf-2-5.jpg" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    <div className="col-lg-6 item">
                                        <img src="./img/single-gallery/pf-2-6.jpg" alt="galleryimage" className="img-fluid"/>
                                    </div>*/}
                                </div>
                            </div>
                            <div className="col-lg-4 de_project-info">
                                <h3>Software de Gestão Condominial - SGC Negocioteca</h3>
                                <p>A Pandora Inovações, uma empresa especializada em desenvolvimento web, comprometida em criar soluções digitais inovadoras para nossos clientes. Temos o prazer de apresentar o SGC Negocioteca, um software de gestão condominial desenvolvido com expertise e dedicação pela nossa equipe.</p>
                                <p>O SGC Negocioteca é o resultado do nosso compromisso em oferecer soluções de alta qualidade e sob medida para atender às necessidades dos condomínios modernos. Desenvolvido com tecnologia de ponta e uma abordagem centrada no usuário, o software oferece uma experiência completa e intuitiva para síndicos, moradores e administradores.</p>
                                <p><b>Recursos Principais:</b></p>
                                <p><b>Acesso a Boletos Simplificado: </b>Facilitamos o acesso aos boletos, tornando o processo de pagamento ágil e conveniente para os moradores.</p>
                                <p><b>Geração de Protocolos Eficiente: </b>O SGC Negocioteca simplifica a comunicação entre moradores e administração, oferecendo uma forma eficiente de gerar protocolos para atendimentos.</p>
                                <p><b>Decisões Comunitárias Transparentes: </b>Promovemos a participação e engajamento da comunidade condominial, permitindo que os moradores votem em demandas e sugestões para decisões coletivas.</p>
                                <p><b>Votos Digitais Seguros: </b>Garantimos transparência e segurança nos processos decisórios, oferecendo votos digitais com comprovante e restrição de voto único.</p>
                                <p><b>Gestão Ágil e Eficiente: </b>O SGC Negocioteca agiliza processos administrativos e promove uma gestão eficiente do condomínio, economizando tempo e recursos.</p>
                                <p><b>Reserva Inteligente de Áreas Comuns: </b>Facilitamos a reserva online de áreas comuns, como churrasqueiras e salões de festas, com um calendário integrado e intuitivo.</p>
                                <p>Na Pandora Inovações, estamos comprometidos em fornecer soluções de alta qualidade e em superar as expectativas dos nossos clientes. O SGC Negocioteca é mais um exemplo do nosso compromisso em oferecer soluções inovadoras e sob medida para cada projeto que empreendemos.</p>                               
                                <h4>Conte Conosco:</h4>
                                <p>Se você busca uma solução digital moderna e eficiente para o seu condomínio, conte com a Pandora Inovações e o SGC Negocioteca. Estamos aqui para simplificar sua administração condominial e proporcionar uma experiência excepcional para seus moradores. Entre em contato conosco e descubra como podemos transformar o seu condomínio hoje mesmo!</p>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa fa-user-o"></i>Cliente: <span>Pandora Inovações</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-file-text-o"></i>Tipo: <span>Plataforma</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-calendar-o"></i>Ano: <span>2023</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-external-link"></i>Site: <span><a href="https://www.sgc.negocioteca.com.br" target="_blank" rel="noreferrer">www.sgc.negocioteca.com.br</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                {/*<blockquote>
                                    "Very well done theme. Versatile, extremely well coded, and gorgeous. That's a great combo. Highly recommended."
                                    <span>John Archi</span>
                                </blockquote>*/}
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* lightbox3 */}
                {lighbx4 && ( 
                  <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose4}></button>
                    </div>
                    <div className="v-center w-100">
                      <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <div className="row g-4">
                                    <div className="col-lg-12 item">
                                        <img src="./img/gallery/sge-2.png" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    <div className="col-lg-6 item">
                                        <img src="./img/gallery/sge-3.png" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    <div className="col-lg-6 item">
                                        <img src="./img/gallery/sge-5.png" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    <div className="col-lg-12 item">
                                        <img src="./img/gallery/sge-4.png" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    <div className="col-lg-12 item">
                                        <img src="./img/gallery/sge-6.png" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    <div className="col-lg-12 item">
                                        <img src="./img/gallery/sge-7.png" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    {/*<div className="col-lg-6 item">
                                        <img src="./img/single-gallery/pf-2-5.jpg" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    <div className="col-lg-6 item">
                                        <img src="./img/single-gallery/pf-2-6.jpg" alt="galleryimage" className="img-fluid"/>
                                    </div>*/}
                                </div>
                            </div>
                            <div className="col-lg-4 de_project-info">
                                <h3>Software de Gestão Escolar ou Cursos - SGE Negocioteca</h3>
                                <p>A Pandora Inovações tem a satisfação de apresentar o SGE Negocioteca, um software de gestão escolar desenvolvido com excelência para atender às demandas das escolas modernas em busca de praticidade e eficiência na administração educacional.</p>
                                <p>O SGE Negocioteca é fruto do nosso compromisso em oferecer soluções digitais inovadoras para simplificar a gestão escolar. Desenvolvido com tecnologia de ponta e uma abordagem centrada no usuário, o software oferece uma experiência completa e intuitiva para professores, alunos e administradores.</p>
                                <p><b>Recursos Principais:</b></p>
                                <p><b>Escola Conectada: </b>Modernize a gestão da sua escola com recursos abrangentes, incluindo matrículas, cursos ou matérias, boletim, turmas, professores e avaliações.</p>
                                <p><b>Controle de Pagamentos: </b>Otimize a administração financeira da sua escola, inserindo boletos e organizando os recebimentos de forma eficiente.</p>
                                <p><b>Geração de Avaliações: </b>Facilite a geração de avaliações e o cadastro de questões, integrando-as ao boletim do aluno para uma avaliação mais precisa e completa.</p>
                                <p><b>Academia Interna: </b>Ofereça treinamentos direcionados ao público interno da escola, promovendo o desenvolvimento contínuo e a capacitação dos colaboradores.</p>
                                <p><b>Boletim Escolar: </b>Tenha controle total sobre as notas dos alunos, permitindo que sejam inseridas através de avaliações online ou pelos professores.</p>
                                <p><b>Gestão Ágil e Eficiente: </b>O SGE Negocioteca agiliza processos administrativos e promove uma gestão escolar eficiente, economizando tempo e recursos.</p>
                                <p><b>Acesso Online e Transparência Total: </b>Proporcionamos acesso instantâneo a informações acadêmicas para alunos, professores e administradores, promovendo transparência e comunicação eficaz entre todos os envolvidos.</p>
                                <p>Na Pandora Inovações, estamos comprometidos em fornecer soluções de alta qualidade e em superar as expectativas dos nossos clientes. O SGE Negocioteca é mais um exemplo do nosso compromisso em oferecer soluções inovadoras e sob medida para cada projeto que empreendemos.</p>
                                <h4>Conte Conosco:</h4>
                                <p>Se você busca uma solução digital moderna e eficiente para a gestão da sua escola, conte com a Pandora Inovações e o SGE Negocioteca. Estamos aqui para simplificar sua administração educacional e proporcionar uma experiência excepcional para sua comunidade escolar. Entre em contato conosco e descubra como podemos transformar sua escola hoje mesmo!</p>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa fa-user-o"></i>Cliente: <span>Pandora Inovações</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-file-text-o"></i>Tipo: <span>Plataforma</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-calendar-o"></i>Ano: <span>2023</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-external-link"></i>Site: <span><a href="https://www.sge.negocioteca.com.br" target="_blank" rel="noreferrer">www.sge.negocioteca.com.br</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                {/*<blockquote>
                                    "Very well done theme. Versatile, extremely well coded, and gorgeous. That's a great combo. Highly recommended."
                                    <span>John Archi</span>
                                </blockquote>*/}
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* lightboxvideo */}
                {/* {lighbx2 && ( 
                  <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose2}></button>
                    </div>
                    <div className="v-center w-100">
                      <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <video className="pop" controls autoPlay loop>
                                  <source src="./video/local-video-2.mp4" type="video/mp4"/>
                                  Your browser does not support the video tag.
                                </video>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Self Hosted Video</h3>
                                <p>Duis ad adipisicing id id in duis nisi qui veniam esse voluptate cillum pariatur cupidatat ut dolore amet aliquip cillum ad minim cillum nulla consectetur dolor culpa deserunt mollit dolor ea pariatur laboris sed tempor laboris quis commodo. Ut duis cupidatat consectetur cillum veniam eu quis minim irure fugiat ut consequat do veniam duis dolor quis cupidatat deserunt ut qui minim id dolor nisi sed non ut proident enim culpa dolor elit dolor aliquip dolore enim excepteur.</p>
                               
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa fa-user-o"></i>Client: <span>Envato</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-file-text-o"></i>Type: <span>Branding</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-calendar-o"></i>Year: <span>2020</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-external-link"></i>Preview: <span><a href="https://www.envato.com" target="_blank" rel="noreferrer">www.envato.com</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                                <blockquote>
                                    "Very well done theme. Versatile, extremely well coded, and gorgeous. That's a great combo. Highly recommended."
                                    <span>John Archi</span>
                                </blockquote>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )} */}

      </div>
    );
}

export default Gallery;